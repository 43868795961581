export const Objectives = {
    PlayerLevel: 1,
    Quest: 2,
    SkillLevel: 3,
    NutritionLevel: 4,
    NutritionHabit: 5,
    NutritionBreakfast: 6,
    NutritionLunch: 7,
    NutritionDinner: 8,
    NutritionSnack: 9,
    HydrationLevel: 10,
    HydrationHabit: 11,
    ExerciseLevel: 12,
    ExerciseMilestone: 13,
    MindfulnessLevel: 14,
    MindfulnessHabit: 15,
    MindfulnessBodyScan: 16,
    MindfulnessSeeing: 17,
    MindfulnessListening: 18,
    MindfulnessBreathing: 19,
    MindfulnessLovingKidness: 20,
    SocialLevel: 21,
    SocialHabit: 22,
    SocialReact: 23
}

export const ObjectivesDesc = {
    PlayerLevel: "Player level",
    Quest: "Quest",
    //#region Nutrition
    SkillLevel: "Skill level",
    NutritionLevel: "Nutrition level",
    NutritionHabit: "Nutririon habit",
    NutritionBreakfast: "Nutrition breakfast",
    NutritionLunch: "Nutrition lunch",
    NutritionDinner: "Nutrition dinner",
    NutritionSnack: "Nutrition snack",
    //#endregion
    //#region Hydration
    HydrationLevel: "Hydration level",
    HydrationHabit: "Hydration habit",
    //#endregion
    //#region Exercise
    ExerciseLevel: "Exercise level",
    ExerciseMilestone: "Exercise milestone",
    //#endregion
    //#region Mindfulness
    MindfulnessLevel: "Mindfulness level",
    MindfulnessHabit: "Mindfulness habit",
    MindfulnessBodyScan: "Mindfulness body scan",
    MindfulnessSeeing: "Mindfulness seeing",
    MindfulnessListening: "Mindfulness listening",
    MindfulnessBreathing: "Mindfulness breathing",
    MindfulnessLovingKidness: "Mindfulness loving kidness",
    //#endregion
    //#region Social
    SocialLevel: "Social level",
    SocialHabit: "Social habit",
    SocialReact: "Social react"
    //#endregion
};