export const Rewards = {
    Experience: 1,
    Currency: 2,
    Achievement: 3,
    Premium: 4
}

export const RewardsDesc  = {
    Experience: "Experience",
    Currency: "Currency",
    Achievement: "Achivement",
    Premium: "Premium"
}