<template>
  <section class="container">
    <p class="title">Add Daily Challenge</p>
    <hr class="separator" />
    <!--Add Daily-->
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="subtitle">Description</p>
        <hr />
        <div class="row">
          <div class="col-12">
            <textarea
              type="text"
              v-model="daily.description"
              class="shadow-sm"
              placeholder="Description"
            ></textarea
            ><br />
            <select v-model="daily.difficulty" class="shadow-sm">
              <option value="">Difficulty</option>
              <option value="1">Easy</option>
              <option value="2">Medium</option>
              <option value="3">Hard</option>
            </select>
          </div>
        </div>
        <br /><br />

        <!--Objectives-->
        <p class="subtitle">Objectives</p>
        <hr />
        <div
          class="row m-0"
          v-for="(input, index) in daily.objectives"
          :key="index"
        >
          <div class="col-9 col-md-10 p-0">
            <input
              type="number"
              class="input-group-alternative mb-3 shadow-sm"
              v-model="daily.objectives[index].target"
              placeholder="Target"
            />
            <select
              v-model="daily.objectives[index].objective"
              class="shadow-sm"
            >
              <option value="">Objective type</option>
              <option
                v-for="(input, index) in Objectives"
                :key="index"
                :value="Objectives[index]"
              >
                {{ ObjectivesDesc[index] }}
              </option>
            </select>
            <hr />
          </div>
          <div
            class="col-3 col-md-2 pl-1 pr-1 pt-0 pb-0"
            style="margin-top: 10px"
          >
            <button v-on:click="AddObjective" class="btn-sm">
              <img src="../assets/img/remove.png" />
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button v-on:click="AddObjective" class="btn-sm float-left">
              Add objective &nbsp;&nbsp;&nbsp;<img
                src="../assets/img/add.png"
              />
            </button>
          </div>
        </div>
        <br /><br />

        <!--Rewards-->
        <p class="subtitle">Rewards</p>
        <hr />
        <div
          class="row m-0"
          v-for="(input, index) in daily.rewards"
          :key="index"
        >
          <div class="col-9 col-md-10 p-0">
            <input
              type="number"
              class="input-group-alternative mb-3 shadow-sm"
              v-model.trim="daily.rewards[index].amount"
              placeholder="Amount"
            />
            <select v-model="daily.rewards[index].rewardType" class="shadow-sm">
              <option value="">Reward type</option>
              <option
                v-for="(input, index) in Rewards"
                :key="index"
                :value="Rewards[index]"
              >
                {{ RewardsDesc[index] }}
              </option>
            </select>
            <hr />
          </div>
          <div
            class="col-3 col-md-2 pl-1 pr-1 pt-0 pb-0"
            style="margin-top: 10px"
          >
            <button v-on:click="AddObjective" class="btn-sm">
              <img src="../assets/img/remove.png" />
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button v-on:click="AddReward" class="btn-sm float-left">
              Add reward &nbsp;&nbsp;&nbsp;<img src="../assets/img/add.png" />
            </button>
          </div>
        </div>
        <br /><br />
        <p v-if="success" class="alert-success">Daily added successfuly</p>
        <p v-if="error != ''" class="alert-error">{{ error }}</p>
        <button v-if="!isEditing" v-on:click.prevent="AddDaily">
          Add daily &nbsp;&nbsp;&nbsp;<img src="../assets/img/add.png" />
        </button>
        <button v-if="isEditing" v-on:click.prevent="UpdateDaily">
          Update daily &nbsp;&nbsp;&nbsp;<img src="../assets/img/edit.png" />
        </button>
        <button v-if="isEditing" v-on:click.prevent="CancelEdit">Cancel</button>
      </div>
      <!--Daily missions-->
      <div class="col-12 col-md-4">
        <div class="row">
          <div class="col-12 mb-2 mt-2">Production daily missions</div>
        </div>
        <hr style="margin: 3px auto" />
        <div
          class="row"
          v-for="(daily, index) in dailyChallengesProduction"
          :key="index"
        >
          <div class="col-12">
            <button
              class="collapse-button"
              type="button"
              @click.stop="ToggleDaily(index)"
            >
              <div class="row ml-0 mr-0">
                <div class="col-2">
                  <img src="../assets/img/task.png" />
                </div>
                <div class="col-8" style="text-align: left">
                  {{ daily.description[0] }}
                </div>
                <div class="col-2">
                  <img
                    v-if="daily.show"
                    src="../assets/img/dropdown_open.png"
                  />
                  <img
                    v-if="!daily.show"
                    src="../assets/img/dropdown_close.png"
                  />
                </div>
              </div>
            </button>
            <div
              class="collapse"
              v-bind:class="{ show: daily.show }"
              style="width: 100%; text-align: left; padding: 5px"
            >
              {{ daily.description[0] }}<br />
              <span class="subtitle-secondary">Difficulty:</span>
              {{ daily.difficulty }}
              <hr />
              Objectives<br /><br />
              <div
                v-for="objective in dailyChallengesProduction[index].objectives"
                :key="objective._id"
              >
                <span class="subtitle-secondary">Target:</span>
                {{ objective.target }}<br />
                <span class="subtitle-secondary">Objective:</span>
                {{ ObjectivesDesc[objective.objective] }}
              </div>
              <hr />
              Rewards<br /><br />
              <div
                v-for="reward in dailyChallengesProduction[index].rewards"
                :key="reward._id"
              >
                <span class="subtitle-secondary">Amount:</span>
                {{ reward.amount }}<br />
                <span class="subtitle-secondary">Reward type:</span>
                {{ RewardsDesc[reward.rewardType] }}
              </div>
              <br />
              <button
                class="btn-sm"
                v-on:click="
                  SetDaily(
                    daily._id,
                    daily.description,
                    daily.difficulty,
                    daily.objectives,
                    daily.rewards
                  )
                "
              >
                <img src="../assets/img/edit.png" />
              </button>
            </div>
            <hr style="margin: 3px auto" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2 mt-2">Test daily missions</div>
        </div>
        <hr style="margin: 3px auto" />
        <div
          class="row"
          v-for="(daily, index) in dailyChallengesTest"
          :key="index"
        >
          <div class="col-12">
            <button
              class="collapse-button"
              type="button"
              @click.stop="ToggleDaily(index)"
            >
              <div class="row ml-0 mr-0">
                <div class="col-2">
                  <img src="../assets/img/task.png" />
                </div>
                <div class="col-8" style="text-align: left">
                  {{ daily.description[0] }}
                </div>
                <div class="col-2">
                  <img
                    v-if="daily.show"
                    src="../assets/img/dropdown_open.png"
                  />
                  <img
                    v-if="!daily.show"
                    src="../assets/img/dropdown_close.png"
                  />
                </div>
              </div>
            </button>
            <div
              class="collapse"
              v-bind:class="{ show: daily.show }"
              style="width: 100%; text-align: left; padding: 5px"
            >
              {{ daily.description[0] }}<br />
              <span class="subtitle-secondary">Difficulty:</span>
              {{ daily.difficulty }}
              <hr />
              Objectives<br /><br />
              <div
                v-for="objective in dailyChallengesTest[index].objectives"
                :key="objective._id"
              >
                <span class="subtitle-secondary">Target:</span>
                {{ objective.target }}<br />
                <span class="subtitle-secondary">Objective:</span>
                {{ ObjectivesDesc[objective.objective] }}
              </div>
              <hr />
              Rewards<br /><br />
              <div
                v-for="reward in dailyChallengesTest[index].rewards"
                :key="reward._id"
              >
                <span class="subtitle-secondary">Amount:</span>
                {{ reward.amount }}<br />
                <span class="subtitle-secondary">Reward type:</span>
                {{ RewardsDesc[reward.rewardType] }}
              </div>
              <br />
              <button
                class="btn-sm"
                v-on:click="
                  SetDaily(
                    daily._id,
                    daily.description,
                    daily.difficulty,
                    daily.objectives,
                    daily.rewards
                  )
                "
              >
                <img src="../assets/img/edit.png" />
              </button>
            </div>
            <hr style="margin: 3px auto" />
          </div>
        </div>
      </div>
    </div>
    <br />
  </section>
</template>
<script>
import DailyService from "@/services/DailyService.js";
import {
  Objectives,
  ObjectivesDesc,
} from "@/resources/enumerables/ObjectiveType";
import { Rewards, RewardsDesc } from "@/resources/enumerables/RewardType";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      Objectives,
      ObjectivesDesc,
      Rewards,
      RewardsDesc,
      daily: {
        _id: "",
        description: "",
        difficulty: "",
        objectives: [{ target: "", objective: "" }],
        rewards: [{ rewardType: "", amount: "" }],
      },
      dailyChallengesProduction: [],
      dailyChallengesTest: [],
      isEditing: false,
      success: false,
      error: "",
    };
  },
  validations: {
    daily: { required },
  },
  mounted() {
    this.GetDailyProduction();
    this.GetDailyTest();
  },
  methods: {
    GetDailyProduction() {
      return DailyService.GetDaily("Production")
        .then(async ({ data }) => {
          this.dailyChallengesProduction = data.response;
          console.log(this.dailyChallengesProduction);
          if (this.dailyChallengesProduction.length > 0)
            this.dailyChallengesProduction.forEach(
              (element) => (element.show = false)
            );
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    GetDailyTest() {
      return DailyService.GetDaily("Production")
        .then(async ({ data }) => {
          this.dailyChallengesTest = data.response;
          if (this.dailyChallengesTest.length > 0)
            this.dailyChallengesTest.forEach(
              (element) => (element.show = false)
            );
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    AddObjective() {
      this.daily.objectives.push({ target: "", objective: "" });
    },
    AddReward() {
      this.daily.rewards.push({ rewardType: "", amount: "" });
    },
    async AddDaily() {
      this.success = false;
      if (!this.CheckForm()) return;
      let data = {
        description: this.daily.description,
        difficulty: this.daily.difficulty,
        objectives: this.daily.objectives,
        rewards: this.daily.rewards,
      };

      return DailyService.AddDaily(data)
        .then(async ({ data }) => {
          console.log(data);
          this.Clear();
          this.GetDaily();
          this.success = true;
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    async UpdateDaily() {
      return DailyService.UpdateDaily(this.daily)
        .then(async ({ data }) => {
          console.log(data);
          this.CancelEdit();
          this.GetDaily();
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    async DeleteDaily(id) {
      return DailyService.DeleteDaily(id)
        .then(async ({ data }) => {
          console.log(data);
          this.Clear();
          this.GetDaily();
        })
        .catch((e) => {
          window.location.href = "/error";
        });
    },
    SetDaily(_id, description, difficulty, objectives, rewards) {
      this.daily._id = _id;
      this.daily.description = description;
      this.daily.difficulty = difficulty;
      this.daily.objectives = objectives;
      this.daily.rewards = rewards;
      this.isEditing = true;
    },
    CancelEdit() {
      this.isEditing = false;
      this.Clear();
    },
    Clear() {
      this.daily = {
        description: "",
        difficulty: "",
        objectives: [{ target: "", objective: "" }],
        rewards: [{ rewardType: "", amount: "" }],
      };
    },
    CheckForm() {
      this.error = "";

      if (!this.daily.description || !this.daily.difficulty)
        this.error = "Invalid or missing params";

      this.daily.objectives.forEach((element) => {
        if (!element.objective || !element.target)
          this.error = "Invalid or missing params";
      });

      this.daily.rewards.forEach((element) => {
        if (!element.rewardType || !element.amount)
          this.error = "Invalid or missing params";
      });

      if (!this.error) return true;
      else return false;
    },
    ToggleDaily(index) {
      this.dailyChallenges[index].show = !this.dailyChallenges[index].show;
    },
  },
};
</script>
<style scoped>
@import "../assets/css/Daily.css";
</style>
